/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import deps from 'dependencies';
import EventsLink from 'appdir/components/general/EventsLink';
import { fetch } from 'appdir/components/general/Util';
import ReactHtmlParser from 'html-react-parser';
import { getAppVideoLink } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Module
 * -----------------------------------------------------------------------------
 */
const Module = props => {
	logger.log('[Module] - props:%o', props);

	const dispatch = useDispatch();
	const { data } = props;
	const [doubleStackItems, setDoubleStackItems] = useState([]);
	
	useEffect(() => {
		async function getModuleData() {
			try {
				const result = await fetch(data?.feed);
				setDoubleStackItems(result?.module[0]?.content?.items);
			} catch (err) {
				logger.log(err);
			}
		}
		if (data.moduleType == 'Double Stack' && data?.feed) {
			getModuleData();
		}
	}, []);
	
	const renderVideoItem = (item) => {
		let strId = item.id;
		strId = strId.substring(7, strId.length-3);

		const videoData = { 
			video: {
				id: item.id,
				title: item.title,
				shareUrl: item.link,
				poster: item.photo
			},
			autoplay:'true'
		};

		if(!window.webview) {
			dispatch(deps.actions.ModalOverlay.loadOverlayModal('VIDEO_MODAL', videoData ))
		} else {
			let videoLink = getAppVideoLink(video.id);
			videoLink?.onclick();
		}
	}

	/** inner content of page module */
	const getPageItem = props => {
		var minute = {};
		minute['share-link'] = data.items.item.link;
		return (
			<div className="content-module page displayWrapper">
				<div className={`content-module-image image-wrapper ${data.items.item.type == 'video' ? 'video':null}`} {...minute} onClick={()=> {data.items.item.type == 'video' && renderVideoItem(data.items.item)}}>
					<img src={data.items.item.photo} alt={data.items.item.title} />
					{data.items.item.type == 'video' ? <div className="playBtn">&nbsp;</div> : null}
				</div>
				<div className="content-module-description">
					<div className="title">{data.items.item.title}</div>
					<div className="text">{ReactHtmlParser(data.items.item.description)}</div>
				</div>
			</div>
		);
	};

	const getDoubleStackItem = item => {
		// logger.log('[Module] getDoubleStackItem - item:%o', item);
		var minute = {};
		minute['share-link'] = item.link;
		return (
			<div className="double-stack-item displayWrapper" key={item.id}>
				<div className={`double-stack-item-image image-wrapper ${item.type == 'video' ? 'video':null}`} {...minute} onClick={()=> {item.type == 'video' && renderVideoItem(item)}}>
					<img src={item.photo} alt={item.title} />
					{item.type == 'video' ? <div className="playBtn">&nbsp;</div> : null}
				</div>
				<div className="double-stack-item-description">
					<div className="title">{item.title}</div>
				</div>
			</div>
		);
	};

	/** return html for module items */
	const getModuleLayout = type => {
		/** double stack module */
		if (type == 'Double Stack' && data?.feed) {
			// logger.log('[Module] getModuleLayout - doubleStackItems:%o', doubleStackItems);

			return (
				<div className="content-module double-stack">
					{doubleStackItems.map((item, i) => {
						/** double stack item with link */
						if (item?.type === 'video') {
							return getDoubleStackItem(item)
						} else if (item?.link !== '') {
							return (
								<EventsLink to={item.link} style="double-stack-module-item" key={i}>
									{getDoubleStackItem(item)}
								</EventsLink>
							);
						} else {
							/** double stack item without link */
							return getDoubleStackItem(item);
						}
					})}
				</div>
			);
		} else if (type == 'Page') {
			/** page module */
			/** page module with link */
			if (data.items?.item?.type === 'video') {
				return getPageItem();
			} else if (data.items?.item?.link) {
				return (
					<EventsLink to={data.items.item.link} style="page-module-item">
						{getPageItem()}
					</EventsLink>
				);
			} else {
				/** page module without link */
				return getPageItem();
			}
		} else {
			return null;
		}
	};

	return getModuleLayout(data.moduleType);
};

export default Module;
