import React from 'react';
import Template from 'components/Template';
import EventsLink from 'components/general/EventsLink';
import MeasurementUtils from 'appdir/lib/analytics';

const IosLanding = props => {
	MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
		pageTitle: `iOS Landing Page`,
	});

	let header_attributes = {
		headerType: 'generic',
		title: 'US Open Apps - iPhone/iPad',
		metaTitle: 'US Open Apps - iPhone/iPad',
		metaDescription:
			'The Official Site of the 2025 US Open Tennis Championships, a USTA event. US Open Apps - iPhone/iPad',
		metaDate: '',
		metaPlayers: '',
	};

	let subheader_attributes = {
		breadcrumbs: [
			{
				link: '/index.html',
				title: 'home',
			},
			{
				link: '/en_US/interactive/mobile/index.html',
				title: 'US Open Apps',
			},
		],
		sponsor: {},
		title: 'iPhone/iPad App',
	};

	return (
		<Template header={header_attributes} subHeader={subheader_attributes}>
			<h1 className="header">iPhone/iPad App</h1>
			<section className="page-content">
				<div className="app_landing_content">
					<div className="app_landing_container">
						<div className="appDetails">
							<div className="divice">
								<img src="/assets/images/misc/iPhone5.png" alt="iPhone" />
							</div>
							<div className="desc">
								<p className="link">
									<EventsLink to="http://itunes.apple.com/us/app/2014-us-open-tennis-championships/id327455869?mt=8&amp;uo=4">
										Download the US Open iPhone App
									</EventsLink>
								</p>
								<p>
									The United States Tennis Association is pleased to offer the official app for the US
									Open Tennis Championships, designed and developed by IBM. Stay connected to all the
									action during the Open, August 19 to September 8, 2024.
								</p>
								<h3>New Features:</h3>
								<h3>Fan Cam</h3>
								<p>
									Cheer for your favorite players from the comfort of your own homes. Submit your
									cheers for a chance to be seen and heard!
								</p>
								<h3>Live at the US Open</h3>
								<p>
									Peek into the US Open with live commentary from around the grounds and covering your
									favorite matches. Live at the US Open will​ be your eyes and ears on the grounds of
									the US Open providing up-to-the-minute news, match highlights, photos, and more.
								</p>
								<h3>Key Features: </h3>
								<h3>Latest</h3>
								<p>
									The interactive home screen offers fans an immersive experience into the US Open
									with immediate access to live scores and latest news, photos, and videos.
								</p>
								<h3>Scores – Analytics & Insights by IBM</h3>
								<p>
									LIVE exclusive point-by-point scoring updates, completed match results, statistics,
									match insights and IBM Keys to the Match during the US Open.
								</p>
								<h3>Players</h3>
								<p>
									Follow and favorite all the Players throughout the 2020 US Open and receive curated
									score, stats, and results
								</p>
								<h3>Other Features</h3>
								<p>Match Highlights, Official Schedule of Play, Draws, Ask the Open</p>
								<div class="ftr">
									<EventsLink to="http://itunes.apple.com/us/app/2014-us-open-tennis-championships/id327455869?mt=8&amp;uo=4">
										<img
											src="/assets/images/misc/apple_app_store_badge.png"
											alt="Available on the App Store"
										/>
									</EventsLink>
								</div>
								<p class="denote">
									iPhone and iPad are trademarks of Apple Inc., registered in the U.S. and other
									countries. App Store is a service mark of Apple Inc.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Template>
	);
};
export default IosLanding;
