import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { fetch } from 'appdir/components/general/Util';
import isEmpty from 'lodash/isEmpty';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import PlayerFilters from 'appdir/components/general/PlayerFilters';
import Results from './Results';
import Template from '../../Template';

const queryString = require('querystring-browser');

const mapStateToProps = (state, props) => {
	return {
		...state['PlayerSearch'],
		...props,
	};
};

const mapDispatchToProps = dispatch => ({
	mount: () => dispatch(deps.actions.PlayerSearch.mount()),
	unmount: () => dispatch(deps.actions.PlayerSearch.unmount()),
});

class PlayerSearch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.props,
			playerSearchResults: undefined,
		};
		this.loaded = false;

		logger.log('[PlayerSearch] props:%o', props);
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log("[PlayerSearch] componentDidUpdate - state:%o", this.state);
		// logger.log("[PlayerSearch] componentDidUpdate - props:%o", this.props);
		// logger.log("[PlayerSearch] componentDidUpdate - prevstate:%o", prevState);
		let searchString = this.props.location.search.replace(/^\?/, '');
		let parsedQs = queryString.parse(searchString);
		let searchTerm = parsedQs.term;

		let prevSearch = this.state.searchedOn;
		if (prevSearch !== searchTerm && this.state.playerSearchResults !== undefined) {
			this.fetchSearchResults(searchTerm);
		} else if (this.props.loadResults && this.state.playerSearchResults === undefined) {
			this.fetchPlayers();
		}
	}

	componentDidMount() {
		logger.log('[PlayerSearch] mounted state:%o', this.state);

		if (this.state.playerSearchResults) {
			this.readyNewSearch();
		} else if (this.props.loadResults && this.state.playerSearchResults === undefined) {
			this.fetchPlayers();
		}
	}

	componentWillUnMount() {
		logger.log('[PlayerSearch] unmounted');
		this.loaded = false;
		this.props.unmount();
		this.setState({
			playerSearchResults: null,
		});
	}

	readyNewSearch() {
		logger.log('[PlayerSearch] readyNewSearch - state:%o', this.state);
		const items = this.state;
		items.playerSearchResults = undefined;
		this.loaded = false;

		this.setState({
			items,
		});
	}

	fetchPlayers() {
		let searchString = this.props.location.search.replace(/^\?/, '');
		let parsedQs = queryString.parse(searchString);
		let searchTerm = parsedQs.term;
		logger.log('[PlayerSearch] fetchPlayers - searchTerm:%o', searchTerm);
		let playersPath = this.props.playersPath;
		if (playersPath) {
			fetch(playersPath).then(result => {
				logger.log('[PlayerSearch] fetchPlayers - data:%o', result);
				this.setState({
					playerList: result,
				});
				this.fetchSearchResults(searchTerm);
			});
		}
	}

	fetchSearchResults(searchTerm) {
		//check searchTerm against players list for including last names
		//logger.log("[PlayerSearch] fetchSearchResults:%o", this.state);
		let searchResults = [];

		if (!isEmpty(this.state.playerList) && !this.loaded) {
			logger.log('[PlayerSearch] fetchSearchResults:%o', this.state);
			this.state.playerList.players.map(item => {
				let lowerCaps = item.last_name.toLowerCase();
				let searched = lowerCaps.indexOf(searchTerm.toLowerCase());
				//if matching result add to searchResults
				if (searched !== -1) {
					searchResults.push(item);
				}
			});

			// pageView(ANALYTICS_PROPS.playerSearch);
			logger.log('[PlayerSearch] searchResults:%o', searchResults);
			if (isEmpty(searchResults)) {
				searchResults = false;
			}
			this.setState({
				playerSearchResults: searchResults,
				searchedOn: searchTerm,
			});
		}
	}

	render() {
		logger.log('[PlayerSearch] render - state:%o', this.state);
		let header_attributes = {
			headerType: 'Players',
			metaTitle: 'US Open Players | Official Site of the 2025 US Open Tennis Championships - A USTA Event',
			metaDescription:
				'Find out all the latest information on your favorite US Open tennis player. Get their stats, bio, related news and photos and upcoming match schedule.',
			metaDate: '',
			metaPlayers: '',
			scroll: false,
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			sponsor: {
				link: 'https://www.emirates.com/us/english/',
				title: 'Presented by',
				name: 'Emirates Airline',
				imgClass: '',
				imgSrc: '/assets/images/logos/emirates_logo_red.png',
				tagline: 'Presented by',
			},
			title: 'Players',
		};

		if (this.props.loadResults) {
			return (
				<Template header={header_attributes} subHeader={subheader_attributes}>
					<section className="wrapper playerSearch">
						<div className="content-title">Players</div>
						<div className="content-filters">
							<PlayerFilters filterBy="players" />
						</div>
						<div className="searchResultsList">
							{this.state.playerSearchResults !== undefined ? (
								<Results data={this.state} />
							) : (
								<LoadingIndicator />
							)}
						</div>
					</section>
				</Template>
			);
		} else {
			return (
				<Template header={header_attributes} subHeader={subheader_attributes}>
					<section className="wrapper playerSearch">
						<div className="content-title">Players</div>
						<div className="content-filters">
							<PlayerFilters filterBy="players" />
						</div>
						<LoadingIndicator />
					</section>
				</Template>
			);
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(PlayerSearch);
