import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		logger.log('[Schedule] actions.mount - %o', params);

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let schedulePath = Config.cmsData.tournamentSchedule;
			if (params?.prefix === 'preview') {
				schedulePath = Config.cmsData.tournamentSchedule.replace(/en_US/, 'preview/en_US')
			}
			let data = {
				status: 'load',
				pageHeader: Config.tournamentSchedule,
				schedulePath: schedulePath,
				adConfig: Config.adConfig,
				preview: params?.prefix === 'preview'? true : false,
			};

			logger.log('[Schedule] action.mount - alldata: %o', data);

			dispatch({ type: deps.actionTypes.SCHEDULE_LOAD, data: data });
		});
	},
};
