/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import deps from 'dependencies';
import { connect } from 'react-redux';
import PageSubMenu from 'appdir/components/common-ui/PageSubMenu';
import EventsLink from 'appdir/components/general/EventsLink';
import Template from 'components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import AdUnitLazy from 'components/general/AdUnitLazy';
import Time from 'components/common-ui/Time';
import StubBox from 'appdir/components/common-ui/StubBox';
import ReactHtmlParser from 'html-react-parser';

/**
 * -----------------------------------------------------------------------------
 * React Component: Home
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['Schedule'],
		stubs: state?.['Config']?.stubPages,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.Schedule.mount()),
});

class Schedule extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
			ticketsUrl: 'https://www.ticketmaster.com/usopentennis?cid=TMSchedule_07122021_phos_chluso_ctusot_endcid',
		};

		this.currentDay = 0;
		this.loadedJson = false;

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Event Schedule',
		});
		//logger.log('[Schedule] constructor - props:%o', props);
	}

	componentDidMount() {
		window.webview && this.props.mount();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.schedulePath && !this.loadedJson) {
			deps.services.SchedulePage.fetch(this.props.schedulePath)
				.then(result => {
					//logger.log('[Schedule] componentDidUpdate - result:%o', result);
					this.loadedJson = true;
					this.setState({
						scheduleData: result.tournament_schedule,
					});
				})
				.catch(error => {
					logger.error('[Schedule] componentDidUpdate error:%o', error);
					this.loadedJson = false;
				});
		}

		logger.log('[Schedule] componentDidUpdate - this:%o', this);
	}

	onDaySelect(id) {
		//logger.log('[Schedule] onDaySelect - id:%o', id);
		this.setState({
			selectedDay: id,
		});

		const currentState = this.state.active;
		this.setState({ active: !currentState });
	}

	getSelectedDay(data) {
		for (var i = 0; i < data.length; i++) {
			if (data[i].current == true) {
				this.currentDay = data[i].id;
				this.setState({
					selectedDay: this.currentDay,
				});
			}
		}
	}

	renderTournamentDay(m) {
		const ticketLinks = m.session.filter(session => { return session.link.url !== '' })
		logger.log('[Schedule] renderTournamentDay - tickets:%o', ticketLinks);
		return (
			<div className="maindraw">
				<div className="main-day-color">
					<div className="date mobile">
						<Time epoch_sec={m.epoch} format="MMM" />
						<span>
							<Time epoch_sec={m.epoch} format="D" />
						</span>
						<Time epoch_sec={m.epoch} format="ddd" />
					</div>
					<div className="date desktop">
						<Time epoch_sec={m.epoch} format="MMM" />
						<span>
							<Time epoch_sec={m.epoch} format="D" />
						</span>
						<Time epoch_sec={m.epoch} format="ddd" />
					</div>
				</div>
				<div className="session-info">
					<div className="content">
						{m.session.map((s, i, ar) => {
							logger.log('[Schedule] render mainInfo session:%o', s, i, ar);
							let prevData = i > 0 ? ar[i-1] : null;
							return (
								<div className="session">
									<div className="sid">{ar[i-1]?.session_id !== s.session_id ? s.session_id : null}</div>
									<div
										className="session-content-wrapper"
										style={{ width: '100%' }}>
										{s.times.map((t, i) => {
											return this.renderMainDraw(t, prevData, s.times.length);
										})}
									</div>
								</div>
							);
						})}
					</div>
					{ticketLinks.length > 0 && ticketLinks[0].link.url ? (
						<div className="ticket-container">
							<EventsLink to={ticketLinks[0].link.url}>
								<i className="icon-tickets" /> {ticketLinks[0].link.text}
							</EventsLink>
						</div>
					) : null}
				</div>
			</div>
		);
	}

	renderWeekContent(week) {
		return (
			<div className="displaySchedule">
			<div className="header">{week.name}</div>

			<div className="background">
				<div className="title">
					<div className="qual-day-color">&nbsp;</div>
					<div className="sid">SESSION</div>
					<div className="gate">Gates</div>
					<div className="start">Play</div>
					<div className="tourn-schedule-event">Event</div>
				</div>

				{week.dates.map(m => {
					logger.log('[Schedule] render mainInfo dates:%o', m);
					return this.renderTournamentDay(m);
				})}
			</div>
		</div>			
		);
	}

	renderScheduleContent() {
		let qualsInfo = this.state.scheduleData.draws.qualifying;
		let mainInfo = this.state.scheduleData.draws.maindraw;
		let weekInfo = this.state.scheduleData.draws;
		let notesInfo = this.state.scheduleData.notes;
		Object.keys(this.state.scheduleData.draws).map((draw, i) => {
			logger.log('[Schedule] renderScheduleContent draws:%o', this.state.scheduleData.draws[draw]);
			i == 0 ? qualsInfo = this.state.scheduleData.draws[draw] : mainInfo = this.state.scheduleData.draws[draw];
		})
		return (
			<>
				<section className="page-content tourn-schedule">
					{!window.webview ? <PageSubMenu mode="Schedule" selected="Tournament Schedule" /> : null}
					{
						Object.keys(this.state.scheduleData.draws).map((week, i) => {
							logger.log('[Schedule] renderScheduleContent draws:%o', this.state.scheduleData.draws[week]);
							return this.renderWeekContent(this.state.scheduleData.draws[week]);
						})
					}
					<div className="footer">
						{/* {ReactHtmlParser(attributes['description'])} */}
						{ notesInfo?.bottom_left ? (<div className="notes left">{ReactHtmlParser(notesInfo.bottom_left)}</div>) :null}
						{ notesInfo?.bottom_right ? (<div className="notes right">{ReactHtmlParser(notesInfo.bottom_right)}</div>) : null}
					</div>
					{!window.webview && this.props.adConfig && (
						<div id={`schedule-ad-3`} className="mip-ad-container schedule-ad" key={`ad-3`}>
							<AdUnitLazy data={{ adType: 'mip' }} />
						</div>
					)}
				</section>
			</>
		);
	}
	renderMainDraw(data, len) {
		logger.log('[Schedule] renderMainDraw - in - gate: %o, start: %o, events: %o', data, data.start, data.events);
		// for (let i = 0; i < data.events.length; i++) {
		logger.log(
			'[Schedule] renderMainDraw - for - gate: %o, start: %o, event:%o',
			data.gate,
			data.start,
			data.events
		);

		return (
			<div className="session-content" style={{ display: 'flex', width: '100%' }}>
				<div className="gate">{data.gate}</div>
				<div className="start">{data.start}</div>
				<div className="tourn-schedule-event">
					<ul>
						{data.events.map((event, i) => {
							return <li key={i}>{event}</li>;
						})}
					</ul>
				</div>
			</div>
		);
		// }
	}

	render() {
		logger.log('[Schedule] render scheduleData this:%o', this);

		let header_attributes = {
			headerType: 'schedule',
			title: this.props?.pageHeader?.title,
			metaTitle: this.props?.pageHeader?.title,
			metaDescription: this.props?.pageHeader?.description,
			metaDate: '',
			metaPlayers: '',
			canonicalLink: 'https://www.usopen.org/en_US/about/eventschedule.html'
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			sponsor: {
				link: 'http://www.rolex.com',
				title: 'Rolex',
				name: 'Rolex',
				imgClass: '',
				imgSrc: '/assets/images/logos/rolex_logo_sm.png',
				tagline: 'In partnership with',
			},
			title: this.props?.pageHeader?.section,
			page_header: this.props?.pageHeader?.headerText,
		};

		if (!this.props.preview && this.props?.stubs && this.props?.stubs?.eventschedule?.stub === 'stub') {
			return (
				<Template header={header_attributes} subHeader={subheader_attributes} hideAd={true}>
					<section className="wrapper scorespage">
						<StubBox
							attributes={{
								title: header_attributes.title,
								message: this.props.stubs.eventschedule.text,
								basePicPath: this.props.basePicPath,
							}}
						/>
					</section>
				</Template>
			);
		} else {
			if (this.loadedJson && this.state.scheduleData) {
				return !window.webview ? (
					<Template header={header_attributes} subHeader={subheader_attributes}>
						{this.renderScheduleContent()}
					</Template>
				) : (
					this.renderScheduleContent()
				);
			} else {
				return (
					<Template>
						<section className="page-content tourn-schedule">
							<LoadingIndicator />
						</section>
					</Template>
				);
			}
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
