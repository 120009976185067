/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MobileNav from 'appdir/components/general/MobileNav';
import Header from 'appdir/components/general/Header';
import HomeHero from 'components/general/HomeHero';
import RolexWatch from 'components/general/RolexWatch';
import NativeContent from 'components/cms/NativeContent';
import deps from 'dependencies';
import { fetch } from 'appdir/components/general/Util';
import MeasurementUtils from 'appdir/lib/analytics';
import TemplateGeneric from 'components/TemplateGeneric';
import OnCourt from 'components/general/OnCourtSchedule';
import AdUnitLazy from 'components/general/AdUnitLazy';
import HomeContentWrapper from 'appdir/components/cms/HomeContent/HomeContentWrapper';
import HomeInfo from 'components/cms/HomeContent/HomeInfo';
import HomeHeroStandalone from 'components/cms/HomeContent/HomeHeroStandalone';
import NewsletterSignUp from 'components/general/NewsletterBanner/NewsletterSignUp';
import SocialChannels from 'components/general/SocialChannels';
import SummaryScoreboard from 'appdir/components/_scoring/SummaryScoreboard';
import FilmStrip from 'components/cms/HomeContent/FilmStrip';
import KeyPoints from 'components/cms/HomeContent/KeyPoints';
import StoryGroup from 'components/cms/HomeContent/StoryGroup';
import SingleFeature from 'components/cms/HomeContent/SingleFeature';
import DoubleStack from 'components/cms/HomeContent/DoubleStack';
import ButtonGroup from 'components/cms/HomeContent/ButtonGroup';
import HomeFavorites from 'components/general/HomeFavorites';
/**
 * -----------------------------------------------------------------------------
 * React Component: HomePreview
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['HomePreview'],
		EventsWindow: state['WindowSize'].EventsWindow,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.HomePreview.mount()),
	// more: (start, count) => dispatch(deps.actions.HomePreview.more(start, count)),
});

class HomePreview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
		};

		// this.dateSelectCallback = this.dateSelectCallback.bind(this);
		// this.loadMoreCallback = this.loadMoreCallback.bind(this);

		this.dataLoaded = false;

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'HomePreview',
		});
	}

	componentWillReceiveProps(nextProps) {
		this.setState(prevState => ({
			...prevState,
			...nextProps,
		}));
	}
	getDropDownList(daysList) {
		let list = [];
		daysList.map((day, index) => {
			index == 0 ? (list[index] = { name: 'Latest News', value: day.file }) : null;
			list[index + 1] = { name: day.displayDate, value: day.file };
		});
		return list;
	}

	componentDidUpdate(prevState) {
		logger.log('[HomePreview] - componentDidUpdate state:%o', this.state);

		if (this.state.homeData && this.dataLoaded == false) {
			let homeData = this.state.homeData;
			if (this.state.prefix == 'preview') {
				homeData = this.state.homeData.replace('en_US/', 'preview/en_US/');
			}
			logger.log('[HomePreview] - componentDidUpdate homeData:%o', homeData);
			this.dataLoaded = true;
			fetch(homeData)
				.then(result => {
					this.setState({
						// heroData: result.promos[0],
						media_wall: result.media_wall,
						content: result.modules,
						rolex: result?.rolex ? result.rolex : 1,
					});
				})
				.catch(error => {
					logger.error('[HomePreview] componentDidUpdate error:%o', error);
					this.dataLoaded = false;
				});

			// fetch(this.state.homeData.filterList)
			// 	.then(result => {
			// 		this.setState({
			// 			dateDropdownData: this.getDropDownList(result.days),
			// 		});
			// 	})
			// 	.catch(error => {
			// 		logger.error('[HomePreview] componentDidUpdate error:%o', error);
			// 		this.dataLoaded = false;
			// 	});
		}
	}

	// dateSelectCallback(value) {
	// 	logger.log('[HomePreview] dateSelectCallback value:%o', value);

	// 	fetch(this.state.jsonServer + value)
	// 		.then(result => {
	// 			this.setState({
	// 				heroData: result.promos[0],
	// 				content: result.content,
	// 			});
	// 			MeasurementUtils.dispatchMeasurementCall('homeDateSelect', {
	// 				date: value,
	// 			});
	// 		})
	// 		.catch(error => {
	// 			logger.error('[HomePreview] dateSelectCallback error:%o', error);
	// 		});
	// }

	// loadMoreCallback() {
	//   logger.log("[HomePreview] loadMoreCallback -- do something");
	//   // this.props.more(this.state.end, 9);
	// }

	getMediaCard(item, i) {
		let windowWidth = this.props.EventsWindow.windowWidth;
		let headerSetting;
		// if (item.stacking_id) {
		// 	MeasurementUtils.dispatchMeasurementCall('HomeComponents', {
		// 		name: item.title,
		// 		type: item.type,
		// 		stacking_id: item.stacking_id,
		// 	});
		// }

		switch (item.type) {
			case 'NativeContent':
				return <NativeContent key={i} data={item} />;
				break;

			case 'OnCourt':
				headerSetting = true;
				return (
					<HomeContentWrapper data={{ ...item, suppressHeader: headerSetting }}>
						<OnCourt
							attributes={{
								data: item,
							}}
						/>
					</HomeContentWrapper>
				);
				break;

			case 'FilmStrip':
				return (
					<HomeContentWrapper data={{ ...item, suppressHeader: false, sponsor: item.sponsor }}>
						<FilmStrip
							attributes={{
								data: item,
								key: i,
								type: 'FilmStrip',
							}}
						/>
					</HomeContentWrapper>
				);
				break;

			case 'HomeHeroLatestStandalone':
				return (
					<HomeContentWrapper data={{ ...item, suppressHeader: true }}>
						<HomeHeroLatestStandalone
							data={{
								...item,
								EventsWindow: this.props.EventsWindow,
							}}
						/>
					</HomeContentWrapper>
				);
				break;

			case 'HomeHero':
				let { media_wall } = this.state ? this.state : false;

				if (!media_wall) {
					return (
						<HomeContentWrapper data={{ ...item, suppressHeader: true }}>
							<HomeHeroStandalone
								data={{
									...item,
									EventsWindow: this.props.EventsWindow,
								}}
							/>
						</HomeContentWrapper>
					);
				} else {
					return null;
				}
				break;

			case 'HomeInfo':
			case 'HomeInfoLeft':
			case 'HomeInfoRight':
				headerSetting = false;

				if (
					item?.desktopPosition?.toLowerCase() !== 'sideleft' &&
					item?.desktopPosition?.toLowerCase() !== 'sideright'
				) {
					/** only when the content is placed in the middle col,
					 * show the header in the HomeInfo - assuming there is no sponsor to display
					 * and make the bulleted list to be two cols */

					headerSetting = true;
				}

				return (
					<HomeContentWrapper data={{ ...item, suppressHeader: headerSetting }}>
						<HomeInfo
							data={{
								...item,
								showHeader: headerSetting,
								EventsWindow: this.props.EventsWindow,
							}}
						/>
					</HomeContentWrapper>
				);
				break;

			case 'NewsletterSignUp':
				return (
					<NewsletterSignUp
						attributes={{
							data: item,
							key: i,
						}}
					/>
				);
				break;

			case 'SocialChannels':
				return (
					<SocialChannels
						attributes={{
							data: item,
							key: i,
						}}
					/>
				);
				break;
			case 'SummaryScoreboard':
				return <SummaryScoreboard data={{ ...item, suppressHeader: true, borderTop: 'red' }} />;
				break;

			case 'Keypoints':
				return (
					<HomeContentWrapper data={{ ...item, suppressHeader: false, sponsor: item.sponsor }}>
						<KeyPoints data={item} />
					</HomeContentWrapper>
				);
				break;

			case 'StoryGroup':
				return (
					<HomeContentWrapper data={{ ...item, suppressHeader: false, sponsor: item.sponsor }}>
						<StoryGroup data={item} />
					</HomeContentWrapper>
				);
				break;

			case 'SingleFeature':
				return (
					<HomeContentWrapper data={{ ...item, suppressHeader: false, sponsor: item.sponsor }}>
						<SingleFeature data={item} />
					</HomeContentWrapper>
				);
				break;

			case 'DoubleStack':
				return (
					<HomeContentWrapper data={{ ...item, suppressHeader: false, sponsor: item.sponsor }}>
						<DoubleStack data={item} />
					</HomeContentWrapper>
				);
				break;

			case 'ButtonGroup':
				return (
					<HomeContentWrapper data={{ ...item, suppressHeader: true }}>
						<ButtonGroup data={item} />
					</HomeContentWrapper>
				);
				break;

			case 'Favorites':
				return (
					<HomeContentWrapper data={{ ...item, suppressHeader: true }}>
						<HomeFavorites status={this.props.status} data={item} />
					</HomeContentWrapper>
				);
				break;

			default:
				return null;
				break;
		}
	}

	renderContents = () => {
		let { content } = this.state;
		let { rolex } = this.state ? this.state : 1;
		let leftSideContent = content?.filter(item => item?.desktopPosition?.toLowerCase() === 'sideleft');
		let rightSideContent = content?.filter(item => item?.desktopPosition?.toLowerCase() === 'sideright');

		let showThreeCols = this.props?.EventsWindow?.numericWindowSize > 4;
		let showTwoCols = this.props?.EventsWindow?.numericWindowSize == 4;
		let showOneCol = this.props?.EventsWindow?.numericWindowSize < 4;

		/** filter the main content (center col) based on the screen size so ad can be inserted at the right spot */
		let mainContent = showOneCol
			? content
			: showTwoCols
			? content?.filter(item => item?.desktopPosition?.toLowerCase() !== 'sideright')
			: content?.filter(
					item =>
						item?.desktopPosition?.toLowerCase() !== 'sideleft' &&
						item?.desktopPosition?.toLowerCase() !== 'sideright'
			  );

		return (
			<section className="content-body home">
				{/** over 1280px - show left rail */
				showThreeCols ? (
					<div className="left-col">
						{leftSideContent.map((item, i) => {
							return (
								<React.Fragment key={`fragment-main-col-${i}`}>
									{this.getMediaCard(item, i)}
								</React.Fragment>
							);
						})}
					</div>
				) : null}

				<div className="main-col">
					{/** [TODO] Ad placement */
					mainContent.map((item, i) => {
						return (
							<React.Fragment key={`fragment-main-col-${i}`}>
								{this.getMediaCard(item, i)}

								{/** show Rolex watch under the first component on the mobile size  */
								i == rolex && showOneCol ? <RolexWatch /> : null}

								{/** [TODO] - Check how this displays as it's not showing up right now - Ad placement */
								i % 4 == 0 && i != content.length && i != 0 ? (
									<div className="topAd">
										<AdUnitLazy
											key={`adunit-${i}`}
											id={`adunit-${i}`}
											data={{ adType: `homeLeader` }}
										/>
									</div>
								) : null}
							</React.Fragment>
						);
					})}
				</div>

				{/** over 1024px - show right rail */
				!showOneCol ? (
					<div className="right-col">
						{rightSideContent.map((item, i) => {
							return (
								<React.Fragment key={`fragment-right-col-${i}`}>
									{this.getMediaCard(item, i)}
									{i == 0 ? <RolexWatch /> : null}
								</React.Fragment>
							);
						})}
					</div>
				) : null}
			</section>
		);
	};

	render() {
		logger.log('[Home] render state:%o', this.state);

		let { heroData } = this.state ? this.state : null;
		let { content } = this.state ? this.state : null;
		let { media_wall } = this.state ? this.state : false;

		let header_propsData = {
			headerType: 'home',
			metaDescription:
				'The official site of the 2025 US Open Tennis Championships.  Live tennis scores, watch every match live stream, listen to live radio, and follow the action behind every match.',
			canonicalLink: 'https://www.usopen.org/index.html',
		};

		return (
			<TemplateGeneric>
				<section className="wrapper homepage">
					<Header attributes={header_propsData} />
					<div className="page-wrap">
						<MobileNav />
						<div className="content-wrap">
							<div className="subPageContent">
								{media_wall ? (
									<React.Fragment>
										<HomeHero />
										<RolexWatch />
									</React.Fragment>
								) : null}
								{content ? (
									<section className="page-content">
										{content.length > 0 ? (
											<section>
												{this.renderContents()}
												{/* {content.map((item, i) => {
													{
														return (
															<React.Fragment key={i}>
																{i % 2 == 0 && i != content.length && i != 0 ? (
																	<div className="topAd">
																		<AdUnitLazy data={{ adType: `homeLeader` }} />
																	</div>
																) : null}
																{this.getMediaCard(item, i)}
															</React.Fragment>
														);
													}
												})} */}
											</section>
										) : null}
									</section>
								) : null}
							</div>
						</div>
					</div>
					<div className="topAd">
						<AdUnitLazy data={{ adType: `homeLeader` }} />
					</div>
				</section>
			</TemplateGeneric>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePreview);
