/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { Link } from 'react-router-dom';
import { fetch } from 'appdir/components/general/Util';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import PlayerFilters from 'appdir/components/general/PlayerFilters';
import StubBox from 'appdir/components/common-ui/StubBox';
import Template from '../../Template';
import PageSubMenu from 'appdir/components/common-ui/PageSubMenu';
import FavoritesModule from 'appdir/components/general/FavoritesModule';
import HomeContentWrapper from 'appdir/components/cms/HomeContent/HomeContentWrapper';
import classNames from 'classnames';
import PlayerSeed from 'appdir/components/general/PlayerSeed';

/**
 * -----------------------------------------------------------------------------
 * React Component: SeedsListPage
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['SeedsListPage'],
		stubs: state['Config'].stubPages,
		scoringVars: state['Config'].scoring,
		seedGender: state['PlayerFilters'].seedGender,
		favorites: state['Controller'].favorites,
		countriesLookUp: state['Controller'].countriesLookUp,
		windowSize: state['Controller'].windowSize,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.SeedsListPage.mount()),
	unmount: data => dispatch(deps.actions.SeedsListPage.unmount(data)),
	filterGender: gender => dispatch(deps.actions.PlayerFilters.filterGender(gender)),
});

class SeedsListPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedIndex: 0,
			loadedJson: false,
			selectedEvent: 'AE',
			showGender: 'F',
			eventGender: '',
		};
		this.scrollToList = createRef();
		// this.updateGender = this.updateGender.bind(this);
		this.eventName = '';

		logger.log('[SeedsListPage] constructor - this: %o', this);
	}

	componentDidMount() {
		logger.log('[SeedsListPage] componentDidMount');
		if (
			// !this.state.seedsList &&
			!this.state.updatedSeedsList &&
			!this.state.loadedJson &&
			// this.props.seedsPath &&
			this.props.updatedSeedsPath &&
			this.props.scoringVars !== undefined
		) {
			this.fetchData();
		}

		MeasurementUtils.dispatchMeasurementCall('playersBySeed', {});
	}

	componentWillUnmount() {
		// logger.log('[SeedsListPage] unmounted');
		this.props.unmount({});
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[SeedsListPage] componentDidUpdate - this:%o', this);
		if (prevState.selectedEvent !== this.state.selectedEvent) {
			if (!this.state.eventData) {
				let data = this.getEventData();
				let seededData = [];
				let eventName = [];

				data.map(player => {
					eventName.push(player.eventName);
					seededData.push(player.seeds);
				});

				this.setState(prevState => {
					return {
						...prevState,
						eventName: eventName,
						seededData: seededData,
						eventGender: this.genderOfevent,
					};
				});
			} else {
				this.setState(prevState => {
					return {
						...prevState,
						eventData: this.getEventData(),
						seededData: this.state.seededData,
					};
				});
			}
		}

		// calculate new data path from path in state and current filters
		if (
			!this.state.loadedJson &&
			!this.isStubbed() &&
			//this.props.seedsPath &&
			this.props.updatedSeedsPath &&
			this.props.scoringVars !== undefined
		) {
			this.fetchData();
		}
	}

	isStubbed() {
		return this.props.stubs && this.props.stubs.competitors
			? this.props.stubs.competitors.stub == 'stub'
				? true
				: false
			: false;
	}

	handleSelectedEvent = val => {
		this.setState({
			selectedEvent: val,
		});
		this.scrollToListHandler();
	};

	handleSelectedChange = event => {
		this.setState({
			selectedEvent: event,
		});
	};

	scrollToListHandler = () => {
		if (this.scrollToList.current !== null) {
			this.scrollToList.current.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
				inline: 'nearest',
			});
		}
	};

	fetchData() {
		logger.log('[SeedsListPage] fetchData - this:%o', this);

		if (this.props.scoringVars.seedsAvailable == 'true') {
			fetch(this.props.updatedSeedsPath)
				.then(data => {
					this.setState(prevState => {
						return { ...prevState, updatedSeedsList: data };
					});
				})
				.catch(error => {
					logger.log('[SeedsListPage] fetchData error loading data: %o', error);
				});
		}

		if (this.props.profilePath) {
			//this.loadedJson = this.props.PlayersIndex.profilePath;
			fetch(this.props.profilePath)
				.then(result => {
					// logger.log('[SeedsListPage] componentDidUpdate SeedsListPage:%o', result);
					this.setState({
						players: result.players,
					});
					//pageView(ANALYTICS_PROPS.playerIndex);
				})
				.catch(error => {
					//logger.log('[RolexScoreboard] componentDidUpdate error:%o',error);
					this.setState({
						status: 'error',
						errorType: error,
					});
				});
		}

		this.setState(prevState => {
			return { ...prevState, loadedJson: true };
		});
	}

	showList = genderList => {
		this.setState({
			showGender: genderList,
		});
	};

	updateGender = gender => {
		this.setState({
			eventGender: gender,
		});
	};

	createTableHeaders() {
		if (this.state?.filters?.listView == 'seeds') {
			return ['Seed', 'Name', '', 'Opponent', 'Round'];
		} else {
			return ['Rank', 'Name'];
		}
	}

	formatTableData(data) {
		if (this.props?.filters?.listView == 'seeds') {
			let seedData = data.seeds;
			return seedData.map((d, i) => {
				return {
					seed: d['rank'],
					name:
						d['tour-id'] != null ? (
							<Link to={`/en_US/players/overview/${d['tour-id']}.html`}>{d['long-name']}</Link>
						) : (
							d['long-name']
						),
					text: d['result'],
					opponent:
						d['opp-tour-id'] != null ? (
							<Link to={`/en_US/players/overview/${d['opp-tour-id']}.html`}>{d['opp-long-name']}</Link>
						) : (
							d['opp-long-name']
						),
					round: d['round'],
				};
			});
		}
	}

	getEventData() {
		let seedingOfEvent = [];
		let selectedEvent = this.state?.selectedEvent;
		// let seedsList = this.state?.seedsList?.seed_results;
		let updatedSeedsList = this.state?.updatedSeedsList?.seed_results;
		let updatedSeedingData = [];
		let gender;

		updatedSeedsList?.map((event, i) => {
			if (selectedEvent === 'AE') {
				updatedSeedingData.push(event);
				return updatedSeedingData;
			}
			if (selectedEvent === event.event_id) {
				updatedSeedingData.push(event);
				this.eventName = event.eventName;
				return updatedSeedingData;
			}
		});

		let playerList = [];
		updatedSeedingData?.map(player => {
			player?.seeds?.map(p => {
				playerList.push(p['tour-id-a']);
				playerList.push(p['tour-id-b']);
			});
		});

		playerList?.map(p => {
			if (p?.includes(['wta'])) {
				gender = 'F';
			}
			if (p?.includes(['atp'])) {
				gender = 'M';
			}
		});

		this.genderOfevent = gender;

		logger.log('updatedSeedsList:', gender, ':', updatedSeedingData, 'state', this.state?.eventGender);
		return updatedSeedingData;
	}

	getEventGender(data) {
		let seedData = [];
		seedData = data;
		let gender;

		let playerList = [];
		data?.map(player => {
			player?.map(p => {
				playerList.push(p['tour-id-a']);
				playerList.push(p['tour-id-b']);
			});
		});

		playerList.map(p => {
			if (p?.includes(['wta'])) {
				gender = 'F';
			}
			if (p?.includes(['atp'])) {
				gender = 'M';
			}
		});
		return gender;
	}

	renderSeededList() {
		let seedingOfEvent = this.getEventData();
		if (seedingOfEvent.length > 0) {
			return (
				<div className="player-section">
					<div className="column">
						<PlayerSeed
							players={this.state.players}
							seed_results={seedingOfEvent}
							gender={this.state?.eventGender}
							showPlayerSeeds={true}
							selectedEvent={this.state?.selectedEvent}
							eventName={this.eventName}
						/>
					</div>
				</div>
			);
		} else {
			return <div className="no-players">There are no players for the event you have selected.</div>;
		}
	}

	renderMobileSeeds(gender) {
		let seedingOfEvent = this.getEventData();
		let selectedEvent = this.state?.selectedEvent;
		let eventGender = this.state?.eventGender;

		if (seedingOfEvent.length > 0) {
			return (
				<div className="player-section">
					<div className="column">
						<PlayerSeed
							gender={gender}
							players={this.state.players}
							seed_results={seedingOfEvent}
							showGender={gender}
							showPlayerSeeds={true}
							selectedEvent={this.state.selectedEvent}
							eventName={this.eventName}
						/>
					</div>
				</div>
			);
		}
		{
			return <div className="no-players">There are no players for the event you have selected.</div>;
		}
	}

	getPageData() {
		let tableTitle = 'Seeded Players';
		let headerData;
		let tableRowData;
		let pageClass;

		if (!this.props.seedGender) {
			//default gender
			this.props.filterGender('men');
		}

		if (this.props.seedGender == 'men') {
			tableTitle;
			headerData = this.createTableHeaders();

			tableRowData = this.formatTableData(this.state.seedsList.seed_results[0]);
		} else if (this.props.seedGender == 'women') {
			tableTitle;
			headerData = this.createTableHeaders();
			tableRowData = this.formatTableData(this.state.seedsList.seed_results[1]);
		}

		return {
			tableTitle: tableTitle,
			headers: headerData,
			data: tableRowData,
			tableContainer: 'competitorList',
			pageClass: pageClass,
		};
	}

	setSelected(index) {
		this.setState(prevState => {
			return { ...prevState, selectedIndex: index };
		});
	}

	render() {
		logger.log('[SeedsListPage] render - this:%o', this);
		let breakpoint = this.props.windowSize;
		let mobileView = ['mobile', 'landscape', 'tablet'];

		let header_attributes = {
			headerType: 'Players',
			metaTitle: 'US Open Players | Official Site of the 2025 US Open Tennis Championships - A USTA Event',
			metaDescription:
				'Find out all the latest information on your favorite US Open tennis player. Get their stats, bio, related news and photos and upcoming match schedule.',
			metaDate: '',
			metaPlayers: '',
			scroll: false,
			canonicalLink: 'https://www.usopen.org/en_US/players/seed.html'
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			sponsor: {
				link: 'https://www.emirates.com/us/english/',
				title: 'Presented by',
				name: 'Emirates Airline',
				imgClass: '',
				imgSrc: '/assets/images/logos/emirates_logo_red.png',
				tagline: 'Presented by',
			},
			title: 'Players',
			page_header: 'Players',
		};

		let activeMClass = '';
		let activeFClass = '';
		let selectM = '';
		let selectF = '';

		if (this.state.showGender === 'M') {
			activeMClass = classNames('mobileView current');
			activeFClass = classNames('mobileView');
			selectM = classNames('header-title show');
			selectF = classNames('header-title');
		} else {
			activeFClass = classNames('mobileView current');
			activeMClass = classNames('mobileView');
			selectM = classNames('header-title');
			selectF = classNames('header-title show');
		}

		if (!this.props.stubs) {
			return null;
		}

		if (this.isStubbed()) {
			return (
				<Template header={header_attributes} subHeader={subheader_attributes} hideAd={true}>
					<section className="wrapper playerSeeds">
						<StubBox
							attributes={{
								title: this.props.stubs.competitors.title,
								message: this.props.stubs.competitors.text,
								basePicPath: this.props.basePicPath,
							}}
						/>
					</section>
				</Template>
			);
		} else {
			if (this.state.loadedJson) {
				if (this.state.updatedSeedsList) {
					//let propsData = this.getPageData();

					return (
						<Template header={header_attributes} subHeader={subheader_attributes}>
							<section className="wrapper playerSeeds">
								<div className="content-filters">
									<PlayerFilters filterBy="seeds" />
								</div>
								<PageSubMenu
									mode="Players"
									selected={this.state.selectedEvent}
									//filters={this.state.filter}
									//filters={this.state.filter}
									//countryData={this.state?.countryPlayersData}
									selectEventHandler={this.handleSelectedEvent}
									// handleSelectChange={this.handleSelectedChange}
									selectedEvent={this.state.selectedEvent}
									scrollToListHandler={this.scrollToListHandler}
								/>
								<h1 className="header">Players</h1>
								<HomeContentWrapper data={{ borderTop: 'yellow', suppressHeader: true }}>
									<FavoritesModule mode="playersindex" playersData={this.state.players} />
								</HomeContentWrapper>
								{this.state.updatedSeedsList ? (
									<div id="seedsList" ref={this.scrollToList}>
										{mobileView.indexOf(breakpoint) == -1 ? (
											<>
												<div className="tableTitle"></div>
												<div className="playerContainer">
													<div className="column-header">
														<div className="header-type-title">WOMEN</div>
														<div className="separator"></div>
														<div className="header-type-title">MEN</div>
													</div>
													{/* <PlayerSeed
														players={this.state.players}
														seed_results={this.getEvent()}
														showPlayerSeeds={true}
													/> */}
													{this.renderSeededList()}
												</div>
											</>
										) : (
											<>
												<div className="mobile-column-header">
													<div
														className={selectF}
														onClick={() => this.showList('F')}
														//hidden={this.state.eventGender == 'M' && this.state.selectedEvent !== 'All Events'}
													>
														WOMEN
													</div>
													<div
														className={selectM}
														onClick={() => this.showList('M')}
														//hidden={this.state.eventGender == 'F' && this.state.selectedEvent !== 'All Events'}
													>
														MEN
													</div>
												</div>
												{/* {this.renderMobileSeeds()} */}
												<div className="column-header"></div>
												{this.state.selectedEvent == 'AE' ||
												this.state.selectedEvent == 'XD' ? (
													<>
														{this.state.showGender == 'F' && (
															<div className={activeFClass}>
																{this.renderMobileSeeds('F')}
															</div>
														)}
														{this.state.showGender == 'M' && (
															<div className={activeMClass}>
																{this.renderMobileSeeds('M')}
															</div>
														)}
													</>
												) : (
													<>
														{this.state.eventGender ? (
															<>
																{this.state.showGender == this.state.eventGender && (
																	<div className={activeMClass}>
																		{this.renderMobileSeeds(this.state.eventGender)}
																	</div>
																)}
																{/* {this.state.showGender == this.state.eventGender && (
																	<div className={activeFClass}>
																		{this.renderMobileSeeds('F')}
																	</div>
																)} */}

																{this.state.showGender !== this.state.eventGender && (
																	<div className="no-players">
																		There are no players for the event you have
																		selected.
																	</div>
																)}
															</>
														) : (
															<>{this.renderMobileSeeds(this.state.eventGender)}</>
														)}
													</>
												)}
											</>
										)}
									</div>
								) : null}
							</section>
						</Template>
					);
				} else {
					return (
						<Template header={header_attributes} subHeader={subheader_attributes}>
							<section className="wrapper playerSeeds">
								<h1 className="header">Players</h1>
								<div className="content-filters">
									<PlayerFilters filterBy="seeds" />
								</div>
								<div id="seedsList">
									<div className="stub-content">
										<div className="stub-text">This content is not currently available.</div>
									</div>
								</div>
							</section>
						</Template>
					);
				}
			} else {
				return (
					<Template header={header_attributes} subHeader={subheader_attributes}>
						<section className="wrapper playerSeeds">
							<h1 className="header">Players</h1>
							<div className="content-filters">
								<PlayerFilters filterBy="seeds" />
							</div>
							<LoadingIndicator />
						</section>
					</Template>
				);
			}
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SeedsListPage);
