/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import Template from 'components/Template';
import EventsLink from 'components/general/EventsLink';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: SlamtrackerMomentumGame
 * -----------------------------------------------------------------------------
 */

const AppLanding = props => {
	MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
		pageTitle: `Mobile Landing Page`,
	});

	logger.log('[AppLanding] - props:%o', props);

	let header_attributes = {
		headerType: 'generic',
		title: 'US Open Apps',
		metaTitle: 'US Open Apps',
		metaDescription: 'The Official Site of the 2025 US Open Tennis Championships, a USTA event. US Open Apps',
		metaDate: '',
		metaPlayers: '',
	};

	let subheader_attributes = {
		breadcrumbs: [
			{
				link: '/index.html',
				title: 'home',
			},
		],
		sponsor: {},
		title: 'US Open Apps',
	};

	const renderLanding = () => {
		return (
			<div className="app_landing_content">
				<div className="app_landing_container">
					<div className="full_mobile_img">
						<img src="/assets/images/misc/watch_follow_explore_1280x720_2024.jpg" alt="Watch. Follow. Explore. US Open mobile apps for iOS and Android" />
					</div>
					<div className="side_by_side">
						<div className="ftr">
							<p>Download the US Open iPhone application to get the best of the US Open Apps.</p>
							{/* <div className="link">
								<EventsLink to="/en_US/interactive/mobile/ios.html">View</EventsLink>
							</div> */}
							<EventsLink
								to="http://itunes.apple.com/us/app/2014-us-open-tennis-championships/id327455869?mt=8&amp;uo=4"
								external="true">
								<img
									src="/assets/images/misc/apple_app_store_badge.png"
									alt="Available on the App Store"
								/>
							</EventsLink>
						</div>
					</div>
					<div className="side_by_side">
						<div className="ftr">
							<p>Download the US Open Android application to get the best of the US Open Apps.</p>
							{/* <div className="link">
								<a href="/en_US/interactive/mobile/android.html">View</a>
							</div> */}
							<a
								href="https://play.google.com/store/apps/details?id=com.ibm.events.android.usopen"
								target="_blank">
								<img src="/assets/images/misc/google-play-badge.png" alt="Get it on Google Play" />
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<Template header={header_attributes} subHeader={subheader_attributes}>
			<h1 className="header">US Open Apps</h1>
			<section className="page-content">{renderLanding()}</section>
		</Template>
	);
};

export default AppLanding;
