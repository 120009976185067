import React from 'react';
import { Logger } from 'appdir/logger';
//import staticData from '../data/json/man/static';
import { getQuerystringValues } from 'appdir/components/general/Util';

window.logger = Logger;

logger.log('version: %o', window.version);
logger.log('commit: %o', window.commit);
logger.log('env: %o', process.env.NODE_ENV);
logger.log('react version: %o', React.version);

if (window.webview) {
	logger.log('[App] webview:%o', window.webview);
	// staticData.webview = true;
}

// export static values loaded from json file at build
export const values = {
	pageTitle: 'Official Site of the 2025 US Open Tennis Championships - A USTA Event',
	pageDescription:
		'The official site of the 2025 US Open Tennis Championships.  Live tennis scores, watch every match live stream, listen to live radio, and follow the action behind every match.',
	tournamentDates: 'August 18 - September 7 2025',
	tournamentYear: '2023',
	lang: 'en_US',
	xmlLang: 'en_US',
	siteUrl: 'www.usopen.org',
	eventId: 'usopen-even',
	configPath: '/en_US/json/gen/config_web.json',
	auth: null,
	wrapper: false,
	powerIndexTitle: 'IBM Power Index',
	matchInsightsTitle: 'IBM Match Insights',
	aiDrawTitle: 'AI Draw Analysis',
};

let parsedQs = getQuerystringValues();
logger.log('querystring: %o', parsedQs);

if (parsedQs.config) {
	values.configPath = values.configPath.replace('.json', '.' + parsedQs.config + '.json');
}
