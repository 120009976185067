import VideoPlayerLanding from './index';
import deps from 'dependencies';

export default {
	path: [
		'/:lang(en_US)/watch/:year?/:name?/:id',
		'/:lang(en_US)/video/watch/:year?/:name?/:id',
		'/:lang(en_US)/video/:date?/:id.html',
		'/:lang(en_US)/videoplayer/index.html',
		'/:lang(en_US)/videoplayer/:date?/:id.html',
	],
	exact: true,
	component: VideoPlayerLanding,
	load: () => deps.actions.VideoPlayerLanding.mount(),
};
