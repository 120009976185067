const defaults = s => {
  /************************** CONFIG SECTION **************************/
  s.currencyCode = "USD";
  s.trackDownloadLinks = true;
  s.trackExternalLinks = true;
  s.trackInlineStats = true;
  s.linkDownloadFileTypes =
    "exe,zip,wav,mov,mpg,avi,wmv,pdf,doc,docx,xls,xlsx,ppt,pptx";
  s.linkInternalFilters =
    "javascript:,usopen.org,ibm-us-open.akacast.akamaistream.net,facebook.com/sharer,twitter.com/share,plus.google.com/share,streak.usta.preplaysports.com";
  s.linkLeaveQueryString = false;
  s.linkTrackVars = "";
  s.linkTrackEvents = "";
  s.usePlugins = true;
  s.suppressEvent2 = "no";
  s.visitorNamespace = "ibminteractive";
  s.trackingServer = "smetrics.usopen.org";
  s.trackingServerSecure = "smetrics.usopen.org";
  //US
  s._tpDST = {
    2012: "3/11,11/4",
    2013: "3/10,11/3",
    2014: "3/9,11/2",
    2015: "3/8,11/1",
    2016: "3/13,11/6",
    2017: "3/12,11/5",
    2018: "3/11,11/4",
    2019: "3/10,11/3"
  };
  function s_doPlugins(s) {
    s.server = window.location.hostname;
    /* uniques calculation for the event */
    s.isEvent = 1;
    //alert(s.isEvent);
    if (s.isEvent == 1) {
      s.prop40 = "2016 US Open Tournament";
      s.prop14 = s.getNewRepeat(999);
    }

    /*time parting*/
    var timeParted = s.getTimeParting("n", "-5");
    s.eVar8 = s.prop8 = timeParted.substring(0, timeParted.indexOf("|"));
    s.eVar9 = s.prop9 = timeParted.substring(timeParted.indexOf("|") + 1);
    if (s.eVar9 == "Sunday" || s.eVar9 == "Saturday") {
      s.eVar10 = s.prop10 = "Weekend";
    } else {
      s.eVar10 = s.prop10 = "Weekday";
    }

    /*internal search tracking*/
    s.prop7 = s.Util.getQueryParam("query");
    s.prop7 = s.prop7 == "Search" ? "" : s.prop7;
    s.prop7 = s.prop7 == "search" ? "" : s.prop7;
    if (s.prop7) {
      s.uniqueSearch = s.prop7 = s.prop7.toLowerCase();
      s.eVar7 = s.prop7;
      s.uniqueSearch = s.getValOnce(s.uniqueSearch, "s_c7", 0);
      if (s.uniqueSearch) s.events = s.apl(s.events, "event1", ",", 2);
    }
    /*featured content*/
    s.eVar13 = s.Util.getQueryParam("fpos");
    s.eVar13 = s.getValOnce(s.eVar13, "s_v13", 0);
    s.eVar14 = s.Util.getQueryParam("fid");
    if (s.eVar14 == "true") {
      s.eVar14 = s.pageName;
    }
    s.eVar14 = s.getValOnce(s.eVar14, "s_v14", 0);
    if (s.eVar13 || s.eVar14) s.events = s.apl(s.events, "event6", ",", 2);
    /*new versus repeat visitor*/
    s.eVar15 = s.prop14 = s.getNewRepeat(999);
    /*external traffic*/
    s.promoReferrer = s.referrer ? s.referrer : document.referrer;
    s.promocheck = s.Util.getQueryParam("promo");
    if (s.promocheck) {
      s.internalPromo =
        s.promoReferrer.indexOf("usopen.org") > -1 ? "true" : "";
    }
    if (!s.internalPromo) {
      s.channelManager("promo", "", "", "1");
      s._channel = s._channel == "Paid Non-Search" ? "Promo" : s._channel;
      s.eVar18 = s._channel;
      s.eVar19 = s._campaignID;
      s.eVar20 = s._keywords;
      s.campaign = s._campaign;
      s.eVar22 = s.crossVisitParticipation(
        s._channel,
        "v22",
        "90",
        "5",
        ">",
        "",
        0
      );
      s.eVar21 = s.crossVisitParticipation(
        s._campaign,
        "v21",
        "90",
        "5",
        ">",
        "",
        0
      );
    }
    /*copying traffic values to conversion variables*/
    s.eVar11 = s.pageName ? s.pageName : "";
    s.eVar12 = s.channel ? s.channel : "";
    s.eVar1 = s.prop1 ? s.prop1 : "";
    s.eVar2 = s.prop2 ? s.prop2 : "";
    s.hier1 = s.eVar3 = s.prop3 ? s.prop3 : "";
    s.eVar4 = s.prop4 ? s.prop4 : "";
    s.eVar5 = s.prop5 ? s.prop5 : "";
    s.eVar6 = s.prop6 ? s.prop6 : "";
    s.eVar15 = s.prop15 ? s.prop15 : "";
    //s.eVar16=s.prop16?s.prop16:"";
    s.eVar17 = s.prop17 ? s.prop17 : "";
    //s.eVar18=s.prop18?s.prop18:"";
    //s.eVar19=s.prop19?s.prop19:"";
    //s.eVar20=s.prop20?s.prop20:"";
    //s.eVar21=s.prop21?s.prop21:"";
    //s.eVar22=s.prop22?s.prop22:"";
    s.eVar23 = s.prop23 ? s.prop23 : "";
    //s.eVar24=s.prop24?s.prop24:"";
    //s.eVar25=s.prop25?s.prop25:"";
    s.eVar26 = s.prop26 ? s.prop26 : "";
    s.eVar27 = s.prop27 ? s.prop27 : "";
    s.eVar28 = s.prop28 ? s.prop28 : "";
    s.eVar29 = s.prop29 ? s.prop29 : "";
    s.eVar30 = s.prop30 ? s.prop30 : "";
    s.eVar36 = s.prop36 ? s.prop36 : "";
    s.eVar53 = s.prop53 ? s.prop53 : "";
    //s.eVar40=s.prop28?s.prop28:"";
    /*deduplicate referrer*/

    s.referrer = s.dedupeReferrer();
    s.bounceCheck(s.eVar18, "event3", "event4");
    /*Exit Link*/
    if (s.linkType == "e") {
      s.events = "";
      s.linkTrackVars = "prop11,prop12,prop13";
      s.linkTrackEvents = "";
    }
    /*download tracking*/
    if (s.linkType == "d") {
      s.events = "";
      s.linkTrackVars = "prop11,prop12,prop13";
      s.linkTrackEvents = "";
    }
    /* external campaign tracking */
    s.campaign = s.Util.getQueryParam("cmpid");
    s.campaign = s.getValOnce(s.campaign, "s_v0", 0);
    /*conversion page view event*/
    s.linkTrackVars = s.apl(s.linkTrackVars, "events", ",", 1);
    s.linkTrackEvents = s.apl(s.linkTrackEvents, "event2", ",", 1);
    s.events = s.apl(s.events, "event2", ",", 1);
    /* dedupe referring instances */
    //s.referrer=s.dedupeReferrer();

    if (s.suppressEvent2 != "no") {
      s.events = null;
      s.events = s.suppressEvent2;
    }

    /* percentage page viewed */
    s.prop51 = s.getPreviousValue(s.pageName, "s_ppn"); //prop51: prev page name
    s.prop52 = s.getPercentPageViewed(); //prop52: max % viewed of prev page
    if (!s.prop51 || s.prop51 == "no value") s.prop52 = ""; //clear max % viewed if no prev page view
  }
  s.doPlugins = s_doPlugins;
  /************************** PLUGINS SECTION *************************/
  /* O=MST time offset, S=Event Start (optional: E= event end) */
  /*getNewRepeat 1.2*/
  s.getNewRepeat = new Function(
    "d",
    "cn",
    "" +
      "var s=this,e=new Date(),cval,sval,ct=e.getTime();d=d?d:30;cn=cn?cn:" +
      "'s_nr';e.setTime(ct+d*24*60*60*1000);cval=s.c_r(cn);if(cval.length=" +
      "=0){s.c_w(cn,ct+'-New',e);return'New';}sval=s.split(cval,'-');if(ct" +
      "-sval[0]<30*60*1000&&sval[1]=='New'){s.c_w(cn,ct+'-New',e);return'N" +
      "ew';}else{s.c_w(cn,ct+'-Repeat',e);return'Repeat';}"
  );
  /*Plugin: downloadLinkHandler 0.5*/
  s.downloadLinkHandler = new Function(
    "p",
    "" +
      "var s=this,h=s.p_gh(),n='linkDownloadFileTypes',i,t;if(!h||(s.linkT" +
      "ype&&(h||s.linkName)))return '';i=h.indexOf('?');t=s[n];s[n]=p?p:t;" +
      "if(s.lt(h)=='d')s.linkType='d';else h='';s[n]=t;return h;"
  );
  /*exitLinkHandler 0.5*/
  s.exitLinkHandler = new Function(
    "p",
    "" +
      "var s=this,h=s.p_gh(),n='linkInternalFilters',i,t;if(!h||(s.linkTyp" +
      "e&&(h||s.linkName)))return '';i=h.indexOf('?');t=s[n];s[n]=p?p:t;h=" +
      "s.linkLeaveQueryString||i<0?h:h.substring(0,i);if(s.lt(h)=='e')s.li" +
      "nkType='e';else h='';s[n]=t;return h;"
  );
  s.p_gh = new Function(
    "" +
      "var s=this;if(!s.eo&&!s.lnk)return '';var o=s.eo?s.eo:s.lnk,y=s.ot(" +
      "o),n=s.oid(o),x=o.s_oidt;if(s.eo&&o==s.eo){while(o&&!n&&y!='BODY'){" +
      "o=o.parentElement?o.parentElement:o.parentNode;if(!o)return '';y=s." +
      "ot(o);n=s.oid(o);x=o.s_oidt}}return o.href?o.href:'';"
  );
  /*bounce rate*/
  s.bounceCheck = new Function(
    "v",
    "e",
    "b",
    "c",
    "" +
      "var s=this,a,b,c=c?c:'bc';if(s.p_fo('bounceCheck')==1){a=s.events?s" +
      ".events+',':'';if(v){s.events=a+e;if(s.c_r(c)){b=parseInt(s.c_r(c))" +
      "+1;s.c_w(c,b,0);}else{s.c_w(c,1,0);}}else{if(s.c_r(c)>=1){s.c_w(c,0" +
      ",0);s.events=a+b;}}}"
  );
  s.p_fo = new Function(
    "n",
    "" +
      "var s=this;if(!s.__fo){s.__fo=new Object;}if(!s.__fo[n]){s.__fo[n]=" +
      "new Object;return 1;}else {return 0;}"
  );
  /*apl*/
  s.apl = new Function(
    "l",
    "v",
    "d",
    "u",
    "" +
      "var s=this,m=0;if(!l)l='';if(u){var i,n,a=s.split(l,d);for(i=0;i<a." +
      "length;i++){n=a[i];m=m||(u==1?(n==v):(n.toLowerCase()==v.toLowerCas" +
      "e()));}}if(!m)l=l?l+d+v:v;return l"
  );
  /*campaign stacking v1.5*/
  s.crossVisitParticipation = new Function(
    "v",
    "cn",
    "ex",
    "ct",
    "dl",
    "ev",
    "dv",
    "" +
      "var s=this,ce;if(typeof(dv)==='undefined')dv=0;if(s.events&&ev){var" +
      " ay=s.split(ev,',');var ea=s.split(s.events,',');for(var u=0;u<ay.l" +
      "ength;u++){for(var x=0;x<ea.length;x++){if(ay[u]==ea[x]){ce=1;}}}}i" +
      "f(!v||v=='')return '';v=escape(v);var arry=new Array(),a=new Array(" +
      "),c=s.c_r(cn),g=0,h=new Array();if(c&&c!='')arry=eval(c);var e=new " +
      "Date();e.setFullYear(e.getFullYear()+5);if(dv==0 && arry.length>0 &" +
      "& arry[arry.length-1][0]==v)arry[arry.length-1]=[v, new Date().getT" +
      "ime()];else arry[arry.length]=[v, new Date().getTime()];var start=a" +
      "rry.length-ct<0?0:arry.length-ct;var td=new Date();for(var x=start;" +
      "x<arry.length;x++){var diff=Math.round((td.getTime()-arry[x][1])/86" +
      "400000);if(diff<ex){h[g]=unescape(arry[x][0]);a[g]=[arry[x][0],arry" +
      "[x][1]];g++;}}var data=s.join(a,{delim:',',front:'[',back:']',wrap:" +
      '"\'"});s.c_w(cn,data,e);var r=s.join(h,{delim:dl});if(ce) s.c_w(cn' +
      ",'');return r;"
  );
  /*join*/
  s.join = new Function(
    "v",
    "p",
    "" +
      "var s = this;var f,b,d,w;if(p){f=p.front?p.front:'';b=p.back?p.back" +
      ":'';d=p.delim?p.delim:'';w=p.wrap?p.wrap:'';}var str='';for(var x=0" +
      ";x<v.length;x++){if(typeof(v[x])=='object' )str+=s.join( v[x],p);el" +
      "se str+=w+v[x]+w;if(x<v.length-1)str+=d;}return f+str+b;"
  );
  /*getNewRepeat 1.2*/
  s.getNewRepeat = new Function(
    "d",
    "cn",
    "" +
      "var s=this,e=new Date(),cval,sval,ct=e.getTime();d=d?d:30;cn=cn?cn:" +
      "'s_nr';e.setTime(ct+d*24*60*60*1000);cval=s.c_r(cn);if(cval.length=" +
      "=0){s.c_w(cn,ct+'-New',e);return'New';}sval=s.split(cval,'-');if(ct" +
      "-sval[0]<30*60*1000&&sval[1]=='New'){s.c_w(cn,ct+'-New',e);return'N" +
      "ew';}else{s.c_w(cn,ct+'-Repeat',e);return'Repeat';}"
  );
  /*Replace v1.0*/
  s.repl = new Function(
    "x",
    "o",
    "n",
    "" +
      "var i=x.indexOf(o),l=n.length;while(x&&i>=0){x=x.substring(0,i)+n+x." +
      "substring(i+o.length);i=x.indexOf(o,i+l)}return x"
  );
  /*time parting*/
  s.getTimeParting = new Function(
    "h",
    "z",
    "" +
      "var s=this,od;od=new Date('1/1/2000');if(od.getDay()!=6||od.getMont" +
      "h()!=0){return'Data Not Available';}else{var H,M,D,U,ds,de,tm,da=['" +
      "Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturda" +
      "y'],d=new Date();z=z?z:0;z=parseFloat(z);if(s._tpDST){var dso=s._tp" +
      "DST[d.getFullYear()].split(/,/);ds=new Date(dso[0]+'/'+d.getFullYea" +
      "r());de=new Date(dso[1]+'/'+d.getFullYear());if(h=='n'&&d>ds&&d<de)" +
      "{z=z+1;}else if(h=='s'&&(d>de||d<ds)){z=z+1;}}d=d.getTime()+(d.getT" +
      "imezoneOffset()*60000);d=new Date(d+(3600000*z));H=d.getHours();M=d" +
      ".getMinutes();M=(M<10)?'0'+M:M;D=d.getDay();U=' AM';if(H>=12){U=' P" +
      "M';H=H-12;}if(H==0){H=12;}D=da[D];tm=H+':'+M+U;return(tm+'|'+D);}"
  );
  /*split v1.5*/
  s.split = new Function(
    "l",
    "d",
    "" +
      "var i,x=0,a=new Array;while(l){i=l.indexOf(d);i=i>-1?i:l.length;a[x" +
      "++]=l.substring(0,i);l=l.substring(i+d.length);}return a"
  );
  /*getValOnce_v1.0*/
  s.getValOnce = new Function(
    "v",
    "c",
    "e",
    "t",
    "" +
      "var s=this,a=new Date,v=v?v:'',c=c?c:'s_gvo',e=e?e:0,i=t=='m'?6000" +
      "0:86400000,k=s.c_r(c);if(v){a.setTime(a.getTime()+e*i);s.c_w(c,v,e" +
      "==0?0:a);}return v==k?'':v"
  );

  /*channelManager v2.2*/
  s.channelManager = new Function(
    "a",
    "b",
    "c",
    "V",
    "" +
      "var s=this,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,t,u,v,w,x,y,z,A,B,C,D,E,F," +
      "G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,W,X,Y;g=s.referrer?s.referrer:documen" +
      "t.referrer;g=g.toLowerCase();if(!g){h='1'}i=g.indexOf('?')>-1?g.ind" +
      "exOf('?'):g.length;j=g.substring(0,i);k=s.linkInternalFilters.toLow" +
      "erCase();k=s.split(k,',');l=k.length;for(m=0;m<l;m++){n=j.indexOf(k" +
      "[m])==-1?'':g;if(n)o=n}if(!o&&!h){p=g;q=g.indexOf('//')>-1?g.indexO" +
      "f('//')+2:0;r=g.indexOf('/',q)>-1?g.indexOf('/',q):i;t=g.substring(" +
      "q,r);t=t.toLowerCase();u=t;P='Referrers';v=s.seList+'>'+s._extraSea" +
      "rchEngines;if(V=='1'){j=s.repl(j,'oogle','%');j=s.repl(j,'ahoo','^'" +
      ");g=s.repl(g,'as_q','*');}A=s.split(v,'>');B=A.length;for(C=0;C<B;C" +
      "++){D=A[C];D=s.split(D,'|');E=s.split(D[0],',');F=E.length;for(G=0;" +
      "G<F;G++){H=j.indexOf(E[G]);if(H>-1){I=s.split(D[1],',');J=I.length;" +
      "for(K=0;K<J;K++){L=s.Util.getQueryParam(I[K],'',g);if(L){L=L.toLowerCase" +
      "();M=L;if(D[2]){u=D[2];N=D[2]}else{N=t}if(V=='1'){N=s.repl(N,'#',' " +
      "- ');g=s.repl(g,'*','as_q');N=s.repl(N,'^','ahoo');N=s.repl(N,'%','" +
      "oogle');}}}}}}}O=s.Util.getQueryParam(a,b);if(O){u=O;if(M){P='Paid Searc" +
      "h'}else{P='Paid Non-Search';}}if(!O&&M){u=N;P='Natural Search'}f=s." +
      "_channelDomain;if(f){k=s.split(f,'>');l=k.length;for(m=0;m<l;m++){Q" +
      "=s.split(k[m],'|');R=s.split(Q[1],',');S=R.length;for(T=0;T<S;T++){" +
      "W=j.indexOf(R[T]);if(W>-1)P=Q[0]}}}d=s._channelParameter;if(d){k=s." +
      "split(d,'>');l=k.length;for(m=0;m<l;m++){Q=s.split(k[m],'|');R=s.sp" +
      "lit(Q[1],',');S=R.length;for(T=0;T<S;T++){U=s.Util.getQueryParam(R[T]);i" +
      "f(U)P=Q[0]}}}e=s._channelPattern;if(e){k=s.split(e,'>');l=k.length;" +
      "for(m=0;m<l;m++){Q=s.split(k[m],'|');R=s.split(Q[1],',');S=R.length" +
      ";for(T=0;T<S;T++){X=O.indexOf(R[T]);if(X==0)P=Q[0]}}}if(h=='1'&&!O)" +
      "{u=P=t=p='Direct Load'}T=M+u+t;U=c?'c':'c_m';if(c!='0'){T=s.getValO" +
      "nce(T,U,0);}if(T)M=M?M:'n/a';s._referrer=T&&p?p:'';s._referringDoma" +
      "in=T&&t?t:'';s._partner=T&&N?N:'';s._campaignID=T&&O?O:'';s._campai" +
      "gn=T&&u?u:'';s._keywords=T&&M?M:'';s._channel=T&&P?P:'';"
  );
  s.seList =
    "search.com|qu>search.about.com|terms>alltheweb.com|query,q>altavista.co,altavista.de|q,r>.aol.,suche.aolsvc.de|q,query>ask.jp,ask.co|q,ask>www.baidu.com|wd>search.biglobe.ne.jp,search.kbg.jp|q,extrakey>business.com/search|query>centrum.cz|q>clix.pt|question>cuil.com|q>daum.net,search.daum.net|q>Dictionary.com,Dictionary|term,query,q>eniro.|search_word>excite.|qkw,s,search,key,q>fireball.de|q,query>goo.ne.jp,search.mobile.goo.ne.jp|MT>google.,googlesyndication.com|q,as_q>icqit.com|q>infoseek.|qt,query>ixquick.com|query>kvasir.no|q,searchExpr>libero.it|query>bing.com|q>.livedoor.com|q,keyword>lycos.|query>mail.ru/search,go.mail.ru/search|q>bing.com|q>myway.com|searchfor>nate.com,search.nate.com|query>naver.com,search.naver.com|query>netscape.com|query,search>search.nifty.com|q>dmoz.org|search>reference.com|q>searchalot.com|query,q>sensis.com.au|find>seznam|w>starmedia.com|q>abcsok.no|q>terra.es|query>tiscali.it,www.tiscali.co.uk|key,query>toile.com|query,q>busca.uol.com.br|q>usseek.com|string>vinden.nl|query>virgilio.it|qs>walla.co.il|q>web.de|su>yahoo.com,yahoo.co.jp|p,va>yandex|text";
  /*dedupeReferrer v1.0*/
  s.dedupeReferrer = new Function(
    "c",
    "b",
    "" +
      "var s=this,a,g,i,j,k,l,m,n,o;g=s.referrer?s.referrer:document.refer" +
      "rer;g=g.toLowerCase();if(g){i=g.indexOf('?')>-1?g.indexOf('?'):g.le" +
      "ngth;j=g.substring(0,i);k=s.linkInternalFilters.toLowerCase();k=s.s" +
      "plit(k,',');l=k.length;for(m=0;m<l;m++){n=j.indexOf(k[m])>-1?g:'';i" +
      "f(n)o=n}if(!o){c=c?c:'_dr';b=b?b-1:'1';a=g;a=s.getValOnce(a,c,0);if" +
      "(a){return a}else{return k[b]}}}"
  );
  /*
   * Plugin: getPercentPageViewed v1.71
   */
  s.getPercentPageViewed = new Function(
    "n",
    "" +
      "var s=this,W=window,EL=W.addEventListener,AE=W.attachEvent,E=['load" +
      "','unload','scroll','resize','zoom','keyup','mouseup','touchend','o" +
      "rientationchange','pan'];W.s_Obj=s;s_PPVid=(n=='-'?s.pageName:n)||s" +
      ".pageName||location.href;if(!W.s_PPVevent){s.s_PPVg=function(n,r){v" +
      "ar k='s_ppv',p=k+'l',c=s.c_r(n||r?k:p),a=c.indexOf(',')>-1?c.split(" +
      "',',10):[''],l=a.length,i;a[0]=unescape(a[0]);r=r||(n&&n!=a[0])||0;" +
      "a.length=10;if(typeof a[0]!='string')a[0]='';for(i=1;i<10;i++)a[i]=" +
      "!r&&i<l?parseInt(a[i])||0:0;if(l<10||typeof a[9]!='string')a[9]='';" +
      "if(r){s.c_w(p,c);s.c_w(k,'?')}return a};W.s_PPVevent=function(e){va" +
      "r W=window,D=document,B=D.body,E=D.documentElement,S=window.screen|" +
      "|0,Ho='offsetHeight',Hs='scrollHeight',Ts='scrollTop',Wc='clientWid" +
      "th',Hc='clientHeight',C=100,M=Math,J='object',N='number',s=W.s_Obj|" +
      "|W.s||0;e=e&&typeof e==J?e.type||'':'';if(!e.indexOf('on'))e=e.subs" +
      "tring(2);s_PPVi=W.s_PPVi||0;if(W.s_PPVt&&!e){clearTimeout(s_PPVt);s" +
      "_PPVt=0;if(s_PPVi<2)s_PPVi++}if(typeof s==J){var h=M.max(B[Hs]||E[H" +
      "s],B[Ho]||E[Ho],B[Hc]||E[Hc]),X=W.innerWidth||E[Wc]||B[Wc]||0,Y=W.i" +
      "nnerHeight||E[Hc]||B[Hc]||0,x=S?S.width:0,y=S?S.height:0,r=M.round(" +
      "C*(W.devicePixelRatio||1))/C,b=(D.pageYOffset||E[Ts]||B[Ts]||0)+Y,p" +
      "=h>0&&b>0?M.round(C*b/h):0,O=W.orientation,o=!isNaN(O)?M.abs(o)%180" +
      ":Y>X?0:90,L=e=='load'||s_PPVi<1,a=s.s_PPVg(s_PPVid,L),V=function(i," +
      "v,f,n){i=parseInt(typeof a==J&&a.length>i?a[i]:'0')||0;v=typeof v!=" +
      "N?i:v;v=f||v>i?v:i;return n?v:v>C?C:v<0?0:v};if(new RegExp('(iPod|i" +
      "Pad|iPhone)').exec(navigator.userAgent||'')&&o){o=x;x=y;y=o}o=o?'P'" +
      ":'L';a[9]=L?'':a[9].substring(0,1);s.c_w('s_ppv',escape(W.s_PPVid)+" +
      "','+V(1,p,L)+','+(L||!V(2)?p:V(2))+','+V(3,b,L,1)+','+X+','+Y+','+x" +
      "+','+y+','+r+','+a[9]+(a[9]==o?'':o))}if(!W.s_PPVt&&e!='unload')W.s" +
      "_PPVt=setTimeout(W.s_PPVevent,333)};for(var f=W.s_PPVevent,i=0;i<E." +
      "length;i++)if(EL)EL(E[i],f,false);else if(AE)AE('on'+E[i],f);f()};v" +
      "ar a=s.s_PPVg();return!n||n=='-'?a[1]:a"
  );
  /*
   * Plugin: getPreviousValue_v1.0 - return previous value of designated
   *   variable (requires split utility)
   */
  s.getPreviousValue = new Function(
    "v",
    "c",
    "el",
    "" +
      "var s=this,t=new Date,i,j,r='';t.setTime(t.getTime()+1800000);if(el" +
      "){if(s.events){i=s.split(el,',');j=s.split(s.events,',');for(x in i" +
      "){for(y in j){if(i[x]==j[y]){if(s.c_r(c)) r=s.c_r(c);v?s.c_w(c,v,t)" +
      ":s.c_w(c,'no value',t);return r}}}}}else{if(s.c_r(c)) r=s.c_r(c);v?" +
      "s.c_w(c,v,t):s.c_w(c,'no value',t);return r}"
  );
};

const props = (params = {}, propvals = {}) => {
  defaults(window.s);
  let s = window.s;

  if (params.page_section.length > 0 && params.page_name.length > 0) {
    s.pageName = params.page_section + ":" + params.page_name; //Page Name

    var props = s.pageName.split(":");

    s.prop1 = props[0] + ":" + props[1]; //Section:Subsection 1
    s.prop2 = s.prop1; //Section:Subsection 1:Subsection 2
    if (props.length > 2) {
      s.prop2 = s.prop2 + ":" + props[2];
    }
    s.prop3 = s.prop2; //Section:Subsection 1:Subsection 2:Subsection 3
    if (props.length > 3) {
      s.prop3 = s.prop3 + ":" + props[3];
    }
    s.prop15 = "en_US"; //Language
    s.channel = props[0]; //Site Section

    /*
		//syndicator adjustments
		if(qsParse.get("view")){
		  if(qsParse.get("view") == "scoreboard"){
			s.events='event5';
			s.linkTrackEvents='event5';
			//alert("sb");
		  }
		}
		*/

    s.prop11 = s.pageName;
    s.prop12 = s.channel;
    s.prop13 = "www";
  } else {
    s.pageName = "";
    s.prop15 = "en_US"; //Language
    s.channel = params.page_section;
  }

  s.prop41 = "D=g";
  s.eVar41 = "D=g";

  for (var key in propvals) {
    logger.log("[adobe] propvals - key:%o props:%o", key, propvals);
    // check also if property is not inherited from prototype
    s[key] = propvals[key];
  }
};

const adobe = {
  key: null,
  initialized: false,

  initialize: key => {
    adobe.key = key;
    adobe.initialized = true;
  },

  adobePageview: (data, propvals) => {
    window.s = window.s_gi(adobe.key);
    props(data, propvals);
    window.s_pgicq();

    logger.log("[adobe] adobePageview - data:%o s:%o", data, window.s);
    window.s.t();
  },

  adobeExternalLink: data => {
    window.s.events = "";
    window.s.linkTrackVars = "events,prop11,prop12,prop13";
    window.s.linkTrackEvents = "";
    logger.log("[adobe] adobeExternalLink - url:%o", data.url);
    window.s.tl(this, "e", data.url);
  },

  adobeMeasureApp: args => {
    let s = window.s;
    let id = "";
    let cm = "";
    let mArgs = args;

    logger.log("[adobe] adobeMeasureApp - args:%o", mArgs);

    //alert(mArgs.length + " actual parameters");
    // If only 1 parameter was passed in, and that
    // parameter contains an array, treat that array
    // as the arguments array.

    if (mArgs.length == 1 && mArgs[0].constructor == Array) {
      mArgs = mArgs[0];
    }

    if (mArgs[0] == "home page") {
      mArgs[0] = "Home";
    }

    id = "";

    for (let i = 0; i < mArgs.length; i++) {
      if (mArgs[i].constructor !== Array) {
        if (i == 0) {
          id += mArgs[i];
        } else {
          id += ":" + mArgs[i];
          cm += ":" + mArgs[i];
        }
      } else {
        logger.log(
          "[adobe] adobeMeasureApp - parameter[%d] is an invalid type:  ",
          i,
          mArgs[i].constructor
        );
        return;
      }
    }

    window.onClick = "";

    if (s.suppressEvent17 == undefined || !s.suppressEvent17) {
      s.events = "event17";
      s.linkTrackEvents = s.apl(s.linkTrackEvents, "event17", ",", 1);
    }
    s.linkTrackVars = s.apl(s.linkTrackVars, "events", ",", 1);

    //Account for Most Popular Photos and Most Popular Videos

    if (mArgs.length == 4) {
      if (mArgs[0] == "Video" && mArgs[3].indexOf("mp4") != -1) {
        s.prop33 = mArgs[3];
        s.linkTrackVars += "prop33";
      }
    }

    if (s.suppressEvent2 != "event17") {
      s.linkTrackVars += ",prop11,prop12,prop13";
    }

    s.tl(window, "o", id);

    // reset linkTrack* values so it's fresh for next call
    s.linkTrackVars = "";
    s.linkTrackEvents = "";
  },

  adobeMeasureAppProps: (args, propvals) => {
    let s = window.s;

    logger.log(
      "[adobe] adobeMeasureAppProps - args:%o props:%o",
      args,
      propvals
    );
    for (var key in propvals) {
      if (propvals[key] != undefined) {
        logger.log("[adobe] propvals - key:%o propval:%o", key, propvals[key]);
        s[key] = propvals[key];
      }
    }

    adobe.adobeMeasureApp(args);
  }
};

export default adobe;
