/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import isEmpty from 'lodash/isEmpty';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import { fetch } from 'appdir/components/general/Util';
import PlayerFilters from 'appdir/components/general/PlayerFilters';
import MeasurementUtils from 'appdir/lib/analytics';
import Template from 'components/Template';
import StubBox from 'appdir/components/common-ui/StubBox';
import FavoritesModule from 'appdir/components/general/FavoritesModule';
import HomeContentWrapper from 'appdir/components/cms/HomeContent/HomeContentWrapper';
import PageSubMenu from 'appdir/components/common-ui/PageSubMenu';
import CountryList from './CountryList';
import CountryPlayersSelect from 'appdir/components/pages/CountryPlayersPage/CountryPlayersSelect';
import classNames from 'classnames';

/**
 * -----------------------------------------------------------------------------
 * React Component: CountryPlayersPage
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['CountryPlayersPage'],
		playerData: state['Config'].scoringData,
		countryPlayers: state['PlayerFilters'].countryPlayers,
		stubs: state['Config'].stubPages,
		windowSize: state['Controller'].windowSize,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	filterCountry: country => dispatch(deps.actions.PlayerFilters.filterCountry(country)),
});

class CountryPlayersPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loadingError: false,
			selectedCounrty: '',
			countryData: [],
			selectedEvent: 'AE',
			eventGender: '',
			currEvent: [],
			showGender: 'F',
		};
		this.loaded = false;
		this.countriesLoaded = false;
		this.scrollToList = createRef();

		logger.log('[CountryPlayersPage] constructor - this:%o', this);
	}

	componentWillUnmount() {
		this.setState({
			countryPlayersData: null,
			countrySelectData: null,
		});
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[CountryPlayersPage] componentDidUpdate - this:%o', this);

		if (prevProps?.countryPlayers !== this.props?.countryPlayers) {
			this.loaded = false;
			this.setState({
				countryPlayersData: null,
			});
		}

		if (prevState.selectedEvent !== this.state?.selectedEvent) {
			this.setState(prevState => {
				return {
					...prevState,
					eventGender: this.genderOfEvent,
					selectedEvent: this.state.selectedEvent,
				};
			});
		}

		if (this.props.paths && this.props.countryPlayers && !this.state.countryPlayersData && !this.loaded) {
			this.loaded = true;
			this.fetchPlayersList();
			//fetch country players
			fetch(this.props.paths.countryPlayer.replace('<country_code>', this.props.countryPlayers))
				.then(data => {
					logger.log('[CountryPlayersPage] componentDidUpdate - data fetched:%o', data);
					this.setState(prevState => {
						return { ...prevState, countryPlayersData: data };
					});
				})
				.catch(e => {
					logger.log('[CountryPlayersPage] componentDidUpdate - error fetching data');
					this.setState({
						loadingError: true,
					});
				});
		}

		if (this.state.countryPlayersData && this.props.countryPlayers && !this.countriesLoaded) {
			this.countriesLoaded = true;
			this.getCountries();
		}

		if (!this.props.countryPlayers && !this.state.countrySelectData) {
			//set default country
			this.props.filterCountry('USA');
		}
	}

	componentDidMount() {
		MeasurementUtils.dispatchMeasurementCall('playersByCountry', {});
	}

	fetchPlayersList = () => {
		fetch(this.props.playerData.players)
			.then(result => {
				this.setState({
					allPlayers: result?.players,
				});
			})
			.catch(error => {
				logger.log('[CountryPlayersPage] fetchPlayersList error:%o', error);
			});
	};

	getCountries() {
		//fetch country list
		fetch(this.props.paths.countries).then(data => {
			let filterData = data.countries.map(d => {
				return { name: d.display, value: d.code };
			});
			this.setState({ countrySelectData: filterData });
		});
	}

	formatEventsEntered(data) {
		let countryData = data;
		let newEventsList = [];
		if (countryData) {
			countryData.map((event, index) => {
				newEventsList.push(event);
				newEventsList[index].events_codes_entered = event.events_codes_entered.toString().split(',');
			});
		}
		return newEventsList;
	}

	handleChange = evt => {
		this.setState({
			value: evt.target.value,
		});
	};

	handleSelectedEvent = val => {
		logger.log('[CountryPlayersPage] handleSelectedEvent - val:%o', val);

		this.setState({
			selectedEvent: val,
		});
		this.scrollToListHandler();
	};

	setGender = gender => {
		this.setState({
			eventGender: gender,
		});
	};

	setSelectedEvent = eventData => {
		this.setState({
			currEvent: eventData,
		});
	};

	showList = genderList => {
		this.setState({
			showGender: genderList,
		});
	};

	scrollToListHandler = () => {
		if (this.scrollToList.current !== null) {
			this.scrollToList.current.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
				inline: 'nearest',
			});
		}
	};

	renderCountryList() {
		let playersByCountry = this.state?.countryPlayersData?.players;
		let event = this.state?.selectedEvent;
		let playersByEvent = [];
		let gender;
		this.formatEventsEntered(playersByCountry).map(player => {
			player.events_codes_entered.map((e, i) => {
				if (event === e) {
					playersByEvent.push(player);
					return playersByEvent;
				}
			});
			if (event === 'AE') {
				playersByEvent.push(player);
				return playersByEvent;
			}
		});

		playersByEvent.map(event => {
			if (event.events_codes_entered.includes('Mixed Doubles')) {
				gender = '';
			} else if (event.sex === 'M') {
				gender = 'M';
			} else if (event.sex === 'F') {
				gender = 'F';
			}
			return gender;
		});

		this.genderOfEvent = gender;

		if (playersByEvent.length > 0) {
			return (
				<div className="player-section">
					<div
						className={`column ${
							this.state?.eventGender == 'M' &&
							this.state?.selectedEvent !== 'AE' &&
							this.state?.selectedEvent !== 'XD'
								? 'hideCol'
								: ''
						}`}>
						<CountryList
							players={playersByEvent}
							countryFlag={this.state?.countryPlayersData?.country_code}
							gender="F"
							eventGender={this.state?.eventGender}
							selectedEvent={this.state?.selectedEvent}
						/>
					</div>
					<div className="separator"></div>
					<div
						className={`column ${
							this.state?.eventGender == 'F' &&
							this.state?.selectedEvent !== 'AE' &&
							this.state?.selectedEvent !== 'XD'
								? 'hideCol'
								: ''
						}`}>
						<CountryList
							players={playersByEvent}
							countryFlag={this.state?.countryPlayersData?.country_code}
							gender={'M'}
							eventGender={this.state?.eventGender}
							selectedEvent={this.state?.selectedEvent}
						/>
					</div>
				</div>
			);
		} else {
			return <div className="no-players">There are no players for the event you have selected.</div>;
		}
	}

	renderMobileCountryList(gender) {
		let playersByCountry = this.state?.countryPlayersData?.players;
		let event = this.state?.selectedEvent;
		let playersByEvent = [];
		let eventGender;
		this.formatEventsEntered(playersByCountry).map(player => {
			player.events_codes_entered.map((e, i) => {
				if (event === e) {
					playersByEvent.push(player);
					return playersByEvent;
				}
			});
			if (event === 'AE') {
				playersByEvent.push(player);
				return playersByEvent;
			}
		});

		playersByEvent.map(event => {
			if (event.events_codes_entered.includes('XD')) {
				eventGender = '';
			} else if (event.sex === 'M') {
				eventGender = 'M';
			} else if (event.sex === 'F') {
				eventGender = 'F';
			}
			return eventGender;
		});
		this.genderOfEvent = eventGender;

		if (playersByEvent.length > 0) {
			return (
				<div className="player-section">
					<div
						className={`column ${
							playersByEvent.length == 0 &&
							this.state?.selectedEvent !== 'AE' &&
							this.state?.selectedEvent !== 'XD'
								? 'hideCol'
								: ''
						}`}>
						<CountryList
							players={playersByEvent}
							countryFlag={this.state?.countryPlayersData?.country_code}
							gender={gender}
							selectedEvent={this.state?.selectedEvent}
						/>
					</div>
				</div>
			);
		} else {
			return <div className="no-players">There are no players for the event you have selected.</div>;
		}
	}

	render() {
		// logger.log('[CountryPlayersPage] render - state:%o', this.state);
		let breakpoint = this.props.windowSize;

		let header_attributes = {
			headerType: 'Players',
			metaTitle: 'US Open Players | Official Site of the 2025 US Open Tennis Championships - A USTA Event',
			metaDescription:
				'Find out all the latest information on your favorite US Open tennis player. Get their stats, bio, related news and photos and upcoming match schedule.',
			metaDate: '',
			metaPlayers: '',
			scroll: false,
			canonicalLink: 'https://www.usopen.org/en_US/players/country.html'
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			sponsor: {
				link: 'https://www.emirates.com/us/english/',
				title: 'Presented by',
				name: 'Emirates Airline',
				imgClass: '',
				imgSrc: '/assets/images/logos/emirates_logo_red.png',
				tagline: 'Presented by',
			},
			title: 'Players',
			page_header: 'Players',
		};

		let activeMClass = '';
		let activeFClass = '';
		let selectM = '';
		let selectF = '';

		if (this.state.showGender === 'M') {
			activeMClass = classNames('mobileView current');
			activeFClass = classNames('mobileView hide');
			selectM = classNames('header-title show');
			selectF = classNames('header-title');
		} else {
			activeFClass = classNames('mobileView current');
			activeMClass = classNames('mobileView hide');
			selectM = classNames('header-title');
			selectF = classNames('header-title show');
		}

		// if (!this.props.countryPlayers || !this.state.countrySelectData) {
		// 	//default country
		// 	this.props.filterCountry('USA');
		// }

		if (this.state.loadingError || this.props?.stubs?.nations?.stub === 'stub') {
			return (
				<Template header={header_attributes} subHeader={subheader_attributes} hideAd={true}>
					<section className="wrapper playerCountry">
						<StubBox
							attributes={{
								title: this.props.stubs.nations.title,
								message: this.props.stubs.nations.text,
								basePicPath: this.state.basePicPath,
							}}
						/>
					</section>
				</Template>
			);
		} else if (this.state.countryPlayersData && this.props.countryPlayers) {
			// this.getCountries();
			return (
				<Template header={header_attributes} subHeader={subheader_attributes}>
					<section className="wrapper playerCountry">
						<div className="content-filters">
							<PlayerFilters filterBy="country" />
						</div>
						<PageSubMenu
							mode="Players"
							selected={this.state.selectedEvent}
							selectEventHandler={this.handleSelectedEvent}
							selectedEvent={this.state.selectedEvent}
							playersByCountry={true}
						/>
						<div className="players-dropdown-container">
							{this.state.countrySelectData ? (
								<CountryPlayersSelect
									selectData={this.state.countrySelectData}
									// filter={this.props.countryPlayers}
								/>
							) : null}
						</div>
						<div className="content-title">Players</div>

						<HomeContentWrapper data={{ borderTop: 'yellow', suppressHeader: true }}>
							<FavoritesModule mode="playersindex" playersData={this.state.allPlayers} />
						</HomeContentWrapper>
						{breakpoint != 'mobile' ? (
							<div id="countryList" ref={this.scrollToList}>
								<div className="column-subtitle">
									<img
										src={this.props.paths.flagImagePath.replace(
											'<code>',
											this.state.countryPlayersData.country_code
										)}
									/>
									<span className="country">{this.state?.countryPlayersData?.message}</span>
									<span className="numOfParticipants">
										({this.state?.countryPlayersData?.particpants})
									</span>
								</div>
								<div className="playerContainer">
									<div className="column-header">
										<div className="header-type-title">WOMEN</div>
										<div className="separator"></div>
										<div className="header-type-title">MEN</div>
									</div>
									{this.renderCountryList()}
								</div>
							</div>
						) : (
							<div id="mobileList">
								<div className="mobile-column-header">
									<div className={selectF} onClick={() => this.showList('F')}>
										Women
									</div>
									<div className={selectM} onClick={() => this.showList('M')}>
										Men
									</div>
								</div>
								<div className="column-header"></div>
								{this.state.selectedEvent == 'AE' || this.state.selectedEvent == 'XD' ? (
									<>
										{this.state.showGender == 'M' && (
											<div className={activeMClass}>{this.renderMobileCountryList('M')}</div>
										)}
										{this.state.showGender == 'F' && (
											<div className={activeFClass}>{this.renderMobileCountryList('F')}</div>
										)}
									</>
								) : (
									<>
										{this.state.eventGender ? (
											<>
												{this.state.showGender == 'M' && (
													<div className={activeMClass}>
														{this.renderMobileCountryList('M')}
													</div>
												)}
												{this.state.showGender == 'F' && (
													<div className={activeFClass}>
														{this.renderMobileCountryList('F')}
													</div>
												)}

												{this.state.showGender !== this.state.eventGender && (
													<div className="no-players">
														There are no players for the event you have selected.
													</div>
												)}
											</>
										) : (
											<>
												<div>{this.renderMobileCountryList(this.state.eventGender)}</div>
											</>
										)}
									</>
								)}
							</div>
						)}
					</section>
				</Template>
			);
		} else {
			return (
				<Template header={header_attributes} subHeader={subheader_attributes}>
					<section className="wrapper playerCountry">
						<div className="content-title">Players</div>
						<div className="content-filters">
							<PlayerFilters filterBy="country" />
						</div>
						<LoadingIndicator />
					</section>
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CountryPlayersPage);
